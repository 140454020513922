html, body, #root {
    height: 100%;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.app-container {
    min-height: inherit;
    display: flex;
    flex-direction: column;
}

.not-selectable {
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}