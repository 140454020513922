.header-container {
    display: flex;
    flex-direction: column;
}

#logo {
    display: block;
    height: 225px;
    width: 225px;
    margin: 20px auto 0;
}

.menu-icon {
    display: none;
    margin: 20px auto;
    width: 40px;
    height: 40px;
}

.button-container {
    height: 40px;
    width: 600px;
    margin: 20px auto;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 600px) {
    .menu-icon {
        display: block;
    }

    .button-container {
        display: none;
        flex-direction: column;
        height: fit-content;
        width: 100%;
    }

    .button-container .active-display {
        display: flex;
    }
}